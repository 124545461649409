(function () {
    'use strict';

    angular.module("aerosApp")
        .factory('configService', configService);

    configService.$inject = ["$http", "aerosApi"];

    function configService($http, aerosApi) {

        var promise = aerosApi.getConfig()
            .then(function (response) {

                var config = response.data.config;

                config.connectorTypes = [
                    {value: "None", label: "None"},
                    {value: "MPO_12F", label: "MPO (12F)"},
                    {value: "MPO_APC_12F", label: "MPO/APC (12F)"},
                    {value: "LC", label: "LC"},
                    {value: "LC_APC", label: "LC/APC"},
                    {value: "SC", label: "SC"},
                    {value: "ST", label: "ST"},
                    {value: "SC_APC", label: "SC/APC"},
                    {value: "FC", label: "FC"},
                    {value: "FC_APC", label: "FC/APC"},
                    {value: "E2000", label: "E2000"},
                    {value: "E2000_APC", label: "E2000/APC"}
                ];

                config.networkTypes = [
                    {value: "Singlemode", name: "Single-mode"},
                    {value: "Multimode", name: "Multimode"}
                ];
                config.linkTypes = [
                    // AEROS-1616 custom checkbox rather than dropdown
                    //{value: "None", label: "None"},
                    {value: "Bulkhead", label: "Bulkhead"},
                    {value: "Patchcord", label: "Patchcord"}
                ];
                config.mpoPolarityTypes = [
                    {value: "A", label: "Polarity A"},
                    {value: "B", label: "Polarity B"},
                    {value: "C", label: "Polarity C"},
                    {value: "Base8", label: "Base 8"}
                ];

                config.passFailStandards = {
                    Singlemode: [
                        {value: 'IEC_61300_3_35_PC_SM_RL_45', label: 'IEC 61300-3-35 PC-SM-RL-45'},
                        {value: 'IEC_61300_3_35_APC_SM', label: 'IEC 61300-3-35 APC SM'},
                        {value: 'IEC_61300_3_35_PC_SM_RL_26', label: 'IEC 61300-3-35 PC-SM-RL-26'},
                        {value: 'ATT_SM_TP_76461', label: 'AT&T SM TP-76461'},
                        {value: 'IPC_610_DC_16_1_1_2_SM', label: 'IPC 610-DC 16.1.1/2 SM'}
                    ],
                    Multimode: [
                        {value: 'IEC_61300_3_35_PC_MM', label: 'IEC 61300-3-35 PC-MM'},
                        {value: 'ATT_MM_TP_76461', label: 'AT&T MM TP-76461'},
                        {value: 'IPC_610_DC_16_1_1_2_MM', label: 'IPC 610-DC 16.1.1/2 MM'}
                    ]
                };

                function getPairedMapping(num, length) {
                    var odd = num % 2;
                    var last = (num === length);
                    if (last && odd) {
                        return num;
                    } else if (odd) {
                        return num + 1;
                    } else {
                        return num - 1;
                    }
                }

                config.defaultMapping = function (mpoPolarity, numFibers) {
                    var fibers = new Array(numFibers);
                    switch (mpoPolarity) {
                        case "B": // 1 - 12
                            _.each(fibers, function (noop, counter) {
                                var i = counter + 1;
                                fibers[counter] = {
                                    fiberId: i,
                                    end1position: i,
                                    end2position: numFibers + 1 - i,
                                    disabled: false
                                };
                            });
                            break;
                        case "C": // 1 - 2
                            _.each(fibers, function (noop, counter) {
                                var i = counter + 1;
                                fibers[counter] = {
                                    fiberId: i,
                                    end1position: i,
                                    end2position: getPairedMapping(i, numFibers),
                                    disabled: false
                                };
                            });
                            break;
                        case "Base8": // disable 5 - 8
                            fibers = new Array(12);
                            _.each(fibers, function (noop, counter) {
                                var i = counter + 1;
                                fibers[counter] = {
                                    fiberId: i,
                                    end1position: i,
                                    end2position: i,
                                    disabled: (i > 4 && i < 9)
                                };
                            });
                            break;
                        default: // 1-1 ("A" and "Custom")
                            _.each(fibers, function (noop, counter) {
                                var i = counter + 1;
                                fibers[counter] = {
                                    fiberId: i,
                                    end1position: i,
                                    end2position: i,
                                    disabled: false
                                };
                            });
                            break;
                    }
                    return fibers;
                };

                function getTestMethods() {
                    return config["testMethods"];
                }

                function getTestDirections() {
                    return config["testDirections"];
                }

                function getConnectorTypes() {
                    return config["connectorTypes"];
                }

                function getLinkTypes() {
                    return config["linkTypes"];
                }

                function getMpoTypes() {
                    return config["mpoPolarityTypes"];
                }

                function getDefaultMapping(mpoPolarity, numFibers) {
                    return config["defaultMapping"](mpoPolarity, numFibers);
                }

                function getNetworkTypes() {
                    return config.networkTypes;
                }

                function getPassFailStandards() {
                    return config.passFailStandards;
                }

                return {
                    getTestMethods: getTestMethods,
                    getTestDirections: getTestDirections,
                    getConnectorTypes: getConnectorTypes,
                    getLinkTypes: getLinkTypes,
                    getMpoTypes: getMpoTypes,
                    getDefaultMapping: getDefaultMapping,
                    getNetworkTypes: getNetworkTypes,
                    getPassFailStandards: getPassFailStandards
                };
            });

        function getPromise() {
            return promise;
        }

        return {
            getPromise: getPromise
        };
    }
})();

